<template>
  <el-main>
    <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
      <el-form-item label="主推商品：" prop="main_push_goods">
        <div>
          <vuedraggable
            v-bind="{
              animation: 200,
              group: 'description',
              disabled: false,
              ghostClass: 'ghost',
              scroll: true,
            }"
          >
            <transition-group>
              <div class="itemBox mb20" v-for="(item, index) in ruleForm.main_push_goods" :key="index">
                <el-input disabled v-model="item.goods_name"></el-input>
                <el-button type="primary" @click="ShowGoodsDialog(index)">选择</el-button>
                <img
                  class="closeImg"
                  @click="delItem('main_push_goods', index)"
                  v-if="ruleForm.main_push_goods.length > 1"
                  src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/enterpriseShop/close.png"
                  alt=""
                />
              </div>
            </transition-group>
          </vuedraggable>
          <div>
            <el-button type="text" @click="clickAdd('main_push_goods')">+ 添加主推商品</el-button>
            <span class="tips">仅支持六个商品</span>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="推荐商品分类：" prop="recommend_categories">
        <div>
          <vuedraggable
            v-bind="{
              animation: 200,
              group: 'description',
              disabled: false,
              ghostClass: 'ghost',
              scroll: true,
            }"
          >
            <transition-group>
              <div v-for="(item, index) in ruleForm.recommend_categories" :key="index">
                <div class="itemBox mb20">
                  <el-input disabled v-model="item.name"></el-input>
                  <el-button type="primary" @click="chooseShowDialog(index)">选择</el-button>
                  <img
                    class="closeImg"
                    @click="delItem('recommend_categories', index)"
                    v-if="ruleForm.recommend_categories.length > 1"
                    src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/enterpriseShop/close.png"
                    alt=""
                  />
                </div>
                <div class="uploadBanner">
                  <ReadyUploadSource
                    :path="item.banner || ''"
                    @getSource="val => (item.banner = val.path)"
                    @removeThis="item.banner = ''"
                    :showStyle="{
                      width: '90px',
                      height: '210px',
                    }"
                  ></ReadyUploadSource>
                  <div class="tips" style="margin-left: 0">建议图片尺寸300*700px,大小不超过1M</div>
                </div>
              </div>
            </transition-group>
          </vuedraggable>
          <div>
            <el-button type="text" @click="clickAdd('recommend_categories')">+ 添加主推商品分类</el-button>
            <span class="tips">建议4-6个分类</span>
          </div>
        </div>
      </el-form-item>
    </el-form>
    <!-- 选择商品弹框 -->
    <el-dialog title="选择商品" :visible.sync="goodsDialog" width="52%">
      <div class="chooseShopBox">
        <div class="searchBox">
          <el-input v-model="goodsConfig.search" placeholder="请输入商家名称"></el-input>
          <el-button type="primary" @click="searchGoods">搜索</el-button>
        </div>
        <div class="showAllList" @scroll="loadPageMore">
          <div class="showAllItem" v-for="(item, index) in allGoodsList" :key="index">
            <div class="info">
              <img :src="item.goods_picture" alt="" />
              <div>{{ item.goods_name }}</div>
            </div>
            <div>￥{{ item.goods_price }}</div>
            <el-button type="text" @click="chooseGoods(item)">选择</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 选择商品弹框 -->
    <el-dialog title="选择分类" :visible.sync="classifyDialog" width="52%">
      <div class="chooseShopBox">
        <div class="searchBox">
          <el-input v-model="classifyConfig.search" placeholder="请输入商品名称"></el-input>
          <el-button type="primary" @click="getClassifyList">搜索</el-button>
        </div>
        <div class="showAllList">
          <div class="showAllItem" v-for="(item, index) in allClassifyList" :key="index">
            <div>{{ item.name }}</div>
            <el-button type="text" @click="chooseClassify(item)">选择</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 保存按钮 -->
    <Preservation @preservation="Preservation"></Preservation>
  </el-main>
</template>

<script>
import vuedraggable from 'vuedraggable';
import Preservation from '@/components/preservation';
import _ from 'lodash';
import ReadyUploadSource from '@/components/readyUploadSource';
export default {
  components: {
    vuedraggable,
    Preservation,
    ReadyUploadSource,
  },
  data() {
    function mainCheck(rule, value, callback) {
      let flag = true;
      for (let i = 0; i < value.length; i++) {
        if (!value[i].id) {
          flag = false;
          break;
        }
      }
      if (!flag) {
        callback(new Error('请选择主推商品'));
      } else {
        callback();
      }
    }
    function recommendCheck(rule, value, callback) {
      let flag = true;
      let str = '';
      for (let i = 0; i < value.length; i++) {
        if (!value[i].id) {
          str = '请选择推荐分类';
          flag = false;
          break;
        }
        if (!value[i].banner) {
          str = '请上传分类banner';
          flag = false;
          break;
        }
      }
      if (!flag) {
        callback(new Error(str));
      } else {
        callback();
      }
    }
    return {
      ruleForm: {
        main_push_goods: [{}],
        recommend_categories: [{}],
      },
      rules: {
        main_push_goods: [{ required: true, validator: mainCheck, trigger: 'change' }],
        recommend_categories: [{ required: true, validator: recommendCheck, trigger: 'change' }],
      },
      goodsDialog: false,
      type: 'main_push_goods',
      chooseIndex: -1,
      allGoodsList: [],
      goodsConfig: {
        search: '',
        page: 1,
        rows: 10,
        total: 0,
      },
      classifyDialog: false,
      allClassifyList: [],
      classifyConfig: {
        search: '',
      },
    };
  },
  created() {
    this.getInfo();
  },
  methods: {
    searchGoods() {
      this.goodsConfig.page = 1;
      this.allGoodsList = [];
      this.getGoodsList();
    },
    ShowGoodsDialog(index) {
      this.goodsConfig = {
        search: '',
        page: 1,
        rows: 10,
        total: 0,
      };
      this.getGoodsList();
      this.chooseIndex = index;
      this.goodsDialog = true;
    },
    chooseShowDialog(index) {
      this.classifyConfig.search = '';
      this.allClassifyList = [];
      this.chooseIndex = index;
      this.getClassifyList();
      this.classifyDialog = true;
    },
    loadPageMore(e) {
      if (e.currentTarget.scrollTop + e.currentTarget.clientHeight >= e.currentTarget.scrollHeight) {
        // 业务逻辑...
        if (this.goodsConfig.total / this.goodsConfig.rows > this.goodsConfig.page) {
          this.goodsConfig.page++;
          this.getGoodsList();
        }
      }
    },
    getGoodsList() {
      let obj = {
        page: this.goodsConfig.page,
        rows: this.goodsConfig.rows,
        status: 1,
      };
      if (this.goodsConfig.search) obj.goods_tag = this.goodsConfig.search;
      this.$axios.post(this.$api.goods.applyAndGoods, obj).then(res => {
        if (res.code == 0) {
          this.allGoodsList = this.allGoodsList.concat(res.result.list);
          this.goodsConfig.total = res.result.total_number;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getClassifyList() {
      let obj = {};
      if (this.classifyConfig.search) obj.name = this.classifyConfig.search;
      this.$axios.post(this.$api.goods.goodsClass, obj).then(res => {
        if (res.code == 0) {
          this.allClassifyList = res.result;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    delItem(type, index) {
      this.ruleForm[type].splice(index, 1);
    },
    clickAdd(type) {
      if (type === 'main_push_goods' && this.ruleForm[type].length == 6) {
        this.$message.warning('已到最大推荐数');
        return;
      }
      this.ruleForm[type].push({});
    },
    chooseGoods(data) {
      this.ruleForm.main_push_goods[this.chooseIndex] = data;
      this.goodsDialog = false;
    },
    chooseClassify(data) {
      this.ruleForm.recommend_categories[this.chooseIndex] = data;
      this.$set(this.ruleForm.recommend_categories[this.chooseIndex], 'banner', '');
      this.classifyDialog = false;
    },
    Preservation() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          let obj = _.cloneDeep(this.ruleForm);
          this.$axios.post(this.$api.Tikmall.setBaseInfo, obj).then(res => {
            if (res.code === 0) {
              this.$message.success('设置成功');
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      });
    },
    getInfo() {
      this.$axios.post(this.$api.Tikmall.getInfo).then(res => {
        if (res.code === 0) {
          if (res.result.info) {
            for (const key in this.ruleForm) {
              if (res.result.info[key]) this.ruleForm[key] = JSON.parse(res.result.info[key]);
            }
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  min-height: 100%;
  background-color: #fff;
  padding-bottom: 80px;
  .mb20 {
    margin-bottom: 20px;
  }
  .tips {
    color: #9f9f9f;
    font-size: 12px;
    margin-left: 20px;
  }
  .el-form {
    .itemBox {
      &:last-child {
        margin-bottom: 0;
      }
      display: flex;
      align-items: center;
      .el-input {
        width: 400px;
      }

      .closeImg {
        width: 20px;
        height: 20px;
        margin-left: 20px;
        cursor: pointer;
      }
    }
  }
  .chooseShopBox {
    .searchBox {
      display: flex;
      align-items: center;
    }
    .showAllList {
      height: 390px;
      width: 100%;
      overflow-y: auto;
      margin-top: 20px;
      .showAllItem {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 4px 20px;
        margin: 6px 0;
        .info {
          display: flex;
          align-items: center;
          img {
            width: 50px;
            height: 50px;
            margin-right: 20px;
          }
          div {
            width: 200px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
      .showAllItem:hover {
        background-color: #faf5f5ef;
      }
    }
  }
}
</style>
